import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from "../../../components/Form/TextField/TextField";
import SelectFieldMulticolor from "../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor";
import SelectField from "../../../components/Form/SelectField/SelectField";


import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckBox from "../../../components/Form/CheckBox/CheckBox";
import { capitalizeFirstLetter } from "../../../components/hooks/HelperFunctions";
import { useGetCalenderCategoryDetailsQuery } from "../../../redux/features/calender/calenderApi";
import SelectFieldMulti from "../../../components/Form/SelectFieldMulticolor/SelectFieldMulti";



// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({


}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#fff",
      borderTop: "#E8EAED 1px solid",
      borderBottom: "#E8EAED 1px solid",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const AddCategory = (props) => {

  const {
    editId,
    userTypes,
    calenderCategoryObject,
    setCalenderCategoryObject,
    calenderCategoryErrorObject,
    setCalenderCategoryErrorObject,
    calenderCategories
  } = props

  const {
    data: calenderCategoryDetails,
    isLoading: isCalenderCategoryDetailsLoading,
    isFetching: isCalenderCategoryDetailsFetching,
    error: calenderCategoryDetailsError,
  } = useGetCalenderCategoryDetailsQuery(editId, {
    skip: !(editId != ""),
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isCalenderCategoryDetailsLoading && !isCalenderCategoryDetailsFetching && editId != "") {
      // setCalenderCategoryObject({
      //   name: data?.name,
      //   academicYearId: data?.academicYearId,
      //   createdBy: data?.createdBy
      // })

      let data = calenderCategoryDetails?.body?.data
      // let foundCategory = calenderCategories?.find((singleCategory) => singleCategory?._id == editId)
      // console.log(foundCategory, 'foundCategory')
      setCalenderCategoryObject({
        ...calenderCategoryObject,
        name: data?.name,
        color: data?.color,
        userType: data?.userType
      })
    }
  }, [isCalenderCategoryDetailsLoading, isCalenderCategoryDetailsFetching, calenderCategoryDetails, editId])


  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  const handleChange = (event, value) => {
    console.log(event.target.value, value);
  };

  // useEffect(() => {
  //   console.log(editId, 'editId')
  //   if (editId != "") {
  //     let foundCategory = calenderCategories?.find((singleCategory) => singleCategory?._id == editId)
  //     console.log(foundCategory, 'foundCategory')
  //     setCalenderCategoryObject({
  //       ...calenderCategoryObject,
  //       name: foundCategory?.name,
  //       color: foundCategory?.color,
  //       userType: foundCategory?.userType
  //     })
  //   }
  // }, [editId])

  // useEffect(() => {

  // },)

  const statustData = [
    { value: "#D50000", statuscolor: "#D50000", select: "Red" },
    { value: "#F6BF26", statuscolor: "#F6BF26", select: "Yellow" },
    { value: "#F4511E", statuscolor: "#F4511E", select: "Orange" },
    { value: "#8E24AA", statuscolor: "#8E24AA", select: "Purple" },
    { value: "#33B679", statuscolor: "#33B679", select: "Green" },
    { value: "#0353A4", statuscolor: "#0353A4", select: "Blue" },
  ];



  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "021541651",
      value: "021541651",
    },

    {
      label: "021541651",
      value: "021541651",
    },

  ]

  const onChangeHandler = (val, e, fieldName) => {
    setCalenderCategoryObject({ ...calenderCategoryObject, [fieldName]: val })
    setCalenderCategoryErrorObject({ ...calenderCategoryErrorObject, [fieldName]: "" })
  }

  useEffect(() => {
    if (userTypes?.length > 0) {
      let userTypesWithHolidaysArray = []
      userTypes?.map((userType) => {
        userTypesWithHolidaysArray.push({
          userType: userType?.name,
          holidayStatus: false
        })
      })
      setCalenderCategoryObject({
        ...calenderCategoryObject,
        userType: userTypesWithHolidaysArray
      })
    }
  }, [userTypes])

  const onHolidayUserTypeChange = (e, propertyName) => {
    console.log(e.target.checked, 'test', e)

    let newUserType = []
    newUserType = calenderCategoryObject?.userType?.map(user =>
      user.userType === propertyName ? { ...user, holidayStatus: e.target.checked } : user
    )

    setCalenderCategoryObject({
      ...calenderCategoryObject,
      userType: newUserType
    })

  }

  const statustDatas = [
    { value: 1, statuscolor: "#7266FB", select: "New" },
    { value: 2, statuscolor: "#FF9800", select: "In-progress" },
    { value: 3, statuscolor: "#3CC480", select: "Completed" },
    { value: 4, statuscolor: "#818E94", select: "On hold" },
    { value: 5, statuscolor: "#C44058", select: "Canceled" },
  ];

  const handleChangess = (event, value) => {
    console.log(event.target.value, value);
  };

  const showUserTypesHoliday = () => {
    let showUserTypesHolidayArray = []
    calenderCategoryObject?.userType?.map((singleUserType) => {
      showUserTypesHolidayArray?.push(<StyledTableRow >
        <StyledTableCell align="left" className="TableInfo">{capitalizeFirstLetter(singleUserType?.userType)}</StyledTableCell>
        <StyledTableCell align="right" style={{ fontWeight: "700" }}>
          <CheckBox
            checked={singleUserType?.holidayStatus}
            onChange={(e) => onHolidayUserTypeChange(e, singleUserType?.userType)}
          />
        </StyledTableCell>
      </StyledTableRow>)
    })
    return showUserTypesHolidayArray
  }

  return (
    <>

      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <TextField
            label="Category Name"
            validation
            CharAlignMid="CharAlignMid"
            placeholder="Enter name"
            value={calenderCategoryObject?.name}
            helperText={calenderCategoryErrorObject?.name}
            error={calenderCategoryErrorObject?.name == "" ? false : true}
            handleChange={(val, e) => onChangeHandler(val, e, 'name')}
            count={50}

          />
        </Grid>


        {/* <Grid item md={12} xs={12}>
          <SelectFieldMulticolor
            labelTop={true}
            label="Select Color"
            validation
            dropdownMarginTop="52px"
            statustData={statustData}
            defaultValue={calenderCategoryObject?.color}
            handleChange={(e) => onChangeHandler(e.target.value, e, 'color')}
            height="52px"
            width="auto"
            Status={true}
            textcolor="#001233"
            AddMoreButton={false}
            AddNewLabel=" Add new category"
          />
          {calenderCategoryErrorObject?.color != "" && <p style={{ color: "red" }}>{calenderCategoryErrorObject?.color}</p>}
        </Grid> */}
        {/*  new field added because the dropdown of previous select field had some issue which are not solvable */}
        <Grid item xs={12}>
          <SelectFieldMulti
            labelTop={true}
            label="Select Color"
            validation
            statusData={statustData}
            value={calenderCategoryObject?.color}
            handleChange={(e) => onChangeHandler(e.target.value, e, 'color')}
            Status={true}
            textcolor="#001233"
            AddMoreButton={false}
            AddNewLabel=" Add new category"
          />
          {calenderCategoryErrorObject?.color !== "" && <p style={{ color: "#d32f2f", fontSize: '12px' }}>{calenderCategoryErrorObject?.color}</p>}
        </Grid>



        <Grid item md={12} xs={12}>
          <div className="TableContainer oddEvenTable">

            <div className="FieldLabel"><label>Select Holiday<span></span></label></div>
            <div style={{ border: '1px solid #E8EAED', borderRadius: '12px', overflow: "hidden", marginBottom: "100px" }}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableBody>

                    <StyledTableRow >
                      <StyledTableCell align="left" className="TableLabelTitles">User types</StyledTableCell>
                      <StyledTableCell align="right" className="TableLabelTitles">Holiday</StyledTableCell>
                    </StyledTableRow>

                    {showUserTypesHoliday()}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>

          </div>
        </Grid>







      </Grid >

    </>
  );
};

export default AddCategory;
