import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { capitalizeWords } from '../../../../hooks/HelperFunctions';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#fff",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        backgroundColor: "#fff",
    },
}));

const Overview = ({ transactionsDetails, coupons, handleDrawerOpenUpdateStatus, studentId }) => {
    const naveigate = useNavigate()


    return (
        <>

            <TableContainer className='OddTableContainer'>
                <div className='TransactionsTableTitle'>Billing</div>
                <Table>
                    <TableBody>
                        <StyledTableRow>

                            <StyledTableCell align="left" className="TableLabel" width="35%">Payment Name</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo">{capitalizeWords(transactionsDetails?.name)}</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"></StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell align="left" className="TableLabel">Type</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo">{capitalizeWords(transactionsDetails?.cycle)}</StyledTableCell>
                            <StyledTableCell align="right" className="TableInfo"></StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell align="left" className="TableLabel">Amount </StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"><div className="FlexBox">${(transactionsDetails?.amount)}
                                {(transactionsDetails?.status === "due") && (<div className="DrawerChipBox DrawerGreyChip"><span></span>Due</div>)}
                                {(transactionsDetails?.status === "success" || transactionsDetails?.status === "active") && (<div className="DrawerChipBox DrawerGreenChip"><span></span>{capitalizeWords(transactionsDetails?.status === "success" ? "Paid" : transactionsDetails?.status)}</div>)}


                            </div>
                            </StyledTableCell>
                            {
                                (((transactionsDetails?.paymentMethod === "card" || transactionsDetails?.paymentMethod === "ach") && transactionsDetails?.status !== "success")) && (<StyledTableCell align="right" className="TableInfo"><Button variant="text" onClick={() => handleDrawerOpenUpdateStatus()}>UPDATE STATUS</Button></StyledTableCell>)
                            }

                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell align="left" className="TableLabel">{transactionsDetails?.paymentDate || (transactionsDetails?.cycle === "recurring" && transactionsDetails?.firstPaymentProcessingDate) ? "Processing Date" : "Due Date"}</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo">{transactionsDetails?.firstPaymentProcessingDate
                                ? moment(transactionsDetails?.firstPaymentProcessingDate)?.format("DD-MMM-YYYY")
                                : "N/A"}</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"></StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            {
                coupons?.length > 0 && <TableContainer className='OddTableContainer'>
                    <div className='TransactionsTableTitle'>Discount</div>
                    <Table>
                        <TableBody>
                            {
                                coupons?.map((coupon) => <StyledTableRow>

                                    <StyledTableCell align="left" className="TableLabel" width="35%">{coupon?.code}</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">${coupon?.discountValue}  {coupon?.discountType === "percentage" ? "off" : ""}</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo"></StyledTableCell>
                                </StyledTableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }



            <TableContainer className='OddTableContainer'>
                <div className='TransactionsTableTitle'>Customer</div>
                <Table>
                    <TableBody>
                        <StyledTableRow>

                            <StyledTableCell align="left" className="TableLabel" width="35%">Student</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"><Button variant="text" onClick={() => naveigate(`/users/${studentId}`)}>{capitalizeWords(
                                transactionsDetails?.student?.firstName
                            )} {capitalizeWords(
                                transactionsDetails?.student?.lastName
                            )}</Button></StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"></StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell align="left" className="TableLabel">Parent/Guardian</StyledTableCell>
                            <StyledTableCell align="left" className="TableInfo"><Button variant="text" sx={{ "&.MuiButtonBase-root": { fontSize: '16px !important' } }}> {transactionsDetails?.parent || "N/A"}</Button></StyledTableCell>
                            <StyledTableCell align="right" className="TableInfo"></StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer >

        </>
    );
};

export default Overview;
