import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';
import { truncateText } from "../../../hooks/HelperFunctions"
import { toast } from 'react-toastify';
import { useFindScanUsersMutation } from '../../../redux/features/attendence/attendanceApi';

const SelectUsersScan = ({ setCheckedUsers, alreadyAddedUsers, selectUsers, attendanceAll, handleCloseSelectUsers, selectedList, selectedListJust, renderFullItem = false }) => {
  const { data, isError, isLoading, error } = useGetUserByAttendanceQuery('staff,teacher,faculty,owner', {
    refetchOnMountOrArgChange: true,
  });

  const [getSearchedUserList, options] = useGetSearchResultMutation();
  const [findScanUsers, findScanUsersOptions] = useFindScanUsersMutation();

  useProtected(error);
  useProtected(findScanUsersOptions.error);

  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([])
  const [asc, setAsc] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  useEffect(() => {
    let usersWithDetails = users?.filter((singleUser) => {
      return selectedUsers.includes(singleUser?._id)
    })
    setCheckedUsers(usersWithDetails)
  }, [selectedUsers])

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        item: item,
        cell: [
          { Check: true, checkBoxColor: 'secondary', width: "48px", padding: "0", user_id: item._id, checked: true },
          {
            textBold: `${truncateText(`${item.firstName} ${item.lastName}`, 35)}`,
            user_id: item._id
            // handleSelect: () => handleSelectUser(item),
          },
          { text: item?.userUniqueID ? item.userUniqueID : 'N/A' },
          { text: item?.userType ? toTitleCase(item.userType) : 'N/A' },
          { text: '' },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      let usersToShow = data?.body?.data?.filter((singleUser) => {
        let userFound = alreadyAddedUsers?.find((singUserAtt) => singUserAtt.userUniqueID == singleUser.userUniqueID)
        return userFound ? false : true
      })

      setRows(convertDataToCellRows(usersToShow));
      setUsers(data.body.data)
    }
  }, [data, isLoading, isError, attendanceAll]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
      width: '45%',
      textAlign: 'left',
      headerOnClick: () => ascendingOrDescendingSortingByName('firstName'),
      sort: "sort",
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '20%',
      textAlign: 'left',
      headerOnClick: () => ascendingOrDescendingSortingByRole('userType'),
      sort: "sort",
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
  ];

  ///=================================== sorting part start =============//
  const ascendingOrDescendingSortingByName = (field) => {
    let sortedUsers = [...users]?.sort((a, b) => {
      const fieldA = a[field]?.trim();
      const fieldB = b[field]?.trim();
      return asc ? fieldA?.localeCompare(fieldB) : fieldB?.localeCompare(fieldA)
    })
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc)
  }

  const ascendingOrDescendingSortingByRole = (field) => {
    let sortedUsers = [...users]
    if (asc) {
      sortedUsers.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedUsers.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc)
  }
  ///=================================== sorting part end =============//

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);


  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "", role: ['staff', 'faculty', 'owner'] });
    searchDataShowing(result)
  };

  const searchDataShowing = (result) => {

    if (result?.data) {
      let usersToShow = data?.body?.data?.filter((singleUser) => {
        let userFound = alreadyAddedUsers?.find((singUserAtt) => singUserAtt.userUniqueID === singleUser.userUniqueID)
        return userFound ? false : true
      })
      const filterData = usersToShow?.filter((user) => user?.userType === "staff" || user?.userType === "faculty")
      setRows(convertDataToCellRows(filterData));
    }
    else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm, role: ['staff', 'faculty', 'owner'] });

    if (result?.data) {
      let usersToShow = result?.data?.body?.data?.filter((singleUser) => {
        let userFound = alreadyAddedUsers?.find((singUserAtt) => singUserAtt.userUniqueID === singleUser.userUniqueID)
        return userFound ? false : true
      })
      const filterData = usersToShow?.filter((user) => user?.userType === "staff" || user?.userType === "faculty")
      setRows(convertDataToCellRows(filterData));
    }
    else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  };
  // =========== search part start ====================== //

  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          showSearchBox={true}
          handleCheckItem={setSelectedUsers}
          selectedCheckList={selectedListJust}
          customCellObjectId="user_id"
          searchBoxPlaceholder="Search users"
          FilterButtonDrawer={false}
          RowsPerPage={25}
          CheckboxDelete={true}
          SearchBoxTop={true}
        />
      </div>
      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } ${selectedUsers.length > 1 ? 'users' : 'user'}`}
      />
    </>
  );
};

export default SelectUsersScan;
